<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxFlex="100%" fxFlexFill fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center"
    class="FooterAlign">
    <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
    <div class="selfRegistration" fxFlex="50%" fxFlexFill fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
        fxLayoutAlign="center">
        <div [hidden]="isLoading">
            <div class="main-content">
              <app-logo [logoConfig]="logoConfig"></app-logo>
              <mat-horizontal-stepper class="panel-bg" #stepper (selectionChange)="onStepChange($event)">
                  <mat-step>
                      <div *ngIf="stepper.selectedIndex === 0 && userDetails && isDelegateFederated === 0">
                          <div>
                              <app-self-registration-validate-user [step]="0" [userDetails]="userDetails"
                                  (notify)="onNotify($event)">
                              </app-self-registration-validate-user>
                          </div>
                      </div>
                  </mat-step>
                  <ng-container *ngIf="loginAppFlag && delegateUser==='NOTSIGNED' else nonDelegate">
                  <mat-step>
                    <div *ngIf="stepper.selectedIndex === 1 && userDetails">
                        <div>
                            <app-delegate-legal-agreement [step]="1" [userDetails]="userDetails" (radioPicked)="federationConsentValue($event)"
                                (notify)="onNotify($event)" (delegateNotify)="onDelegateNotify($event) ">
                            </app-delegate-legal-agreement>
                        </div>
                    </div>
                </mat-step>
                </ng-container>
                    <ng-template #nonDelegate>
                </ng-template>                 
              </mat-horizontal-stepper>
              <div *ngIf="isDelegateFederated === 1">
                <app-self-registration-success [custFed]="isDelegateFederated"></app-self-registration-success>
              </div>
          </div>
          <app-footer></app-footer>
        </div>
    </div>
</div>