import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { SpringboardSetupService } from 'src/app/core/services/springboard-setup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthenticationService,
    private readonly appConfig: AppConfigService,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly springboardSetupService: SpringboardSetupService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      // Remove springboard apps in redis cache
      const token = this.cookieLogoSvc.getCookie('car-ses-tok')
      const partyId = this.cookieLogoSvc.getCookie('app-context')
      if (token && partyId) {
        await firstValueFrom(this.springboardSetupService.getApps(token, partyId, 'remove'));
      }
      // Remove cookies
      this.cookieLogoSvc.removeAllCookies();
      // Sign out of Okta and redirect
      this.authService.signOut().then(() => {
        this.navigateToLogin(params); // Redirect to login once all logout processing is done
      }).catch(() => {
        this.navigateToLogin(params); // Not Okta authenticated
      });
    });
  }

  navigateToLogin(params) {
    let extras: any;
    if (params && params.code) {
      extras = {
        queryParams: {
          code: params.code
        }
      };
    }
    this.router.navigate(['login'], extras);
  }
}
