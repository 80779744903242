import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutComponent {


  /**
   * Base constructor
   * @param _snackRef MatSnackBarRef
   */

  constructor(
      private readonly _snackRef: MatSnackBarRef<SessionTimeoutComponent>,
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
) { }

  /** To Dismiss snackbar */
  dismiss() {
    this._snackRef.dismissWithAction();
  }

}
