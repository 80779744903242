import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { APIResponse } from '../models/APIResponse';
import { User } from '../models/User';
import { UserRegister } from '../models/UserRegister';
import { checkUserResponse, userDetails, checkCandidateResponse } from 'src/UnitTest-Support/Mocks/data.mock';

/** user service to post details of login to the server
 * header
 */
@Injectable({
  providedIn: 'root'
})
export class SelfRegistrationSetupService {
  currentError: APIResponse;
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
  ) { }

  registerUser(request: UserRegister): Observable<any> {
    return this.baseClient.postUnauthorizedHandler<any>('/user/register', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        return of(err)
      })
    );
  }

  getUser(userId: string): Observable<User> {
    return this.baseClient.getById<User>('/user/' + userId).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: User = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  validateRegistration(userId: string): Observable<any> {
    return this.baseClient.getNoErrorHandler<any>(`/user/validateRegistration/${userId}`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        return of(err)
      })
    );
  }
  expireRegistrationInvite(userDetails: User): Observable<any> {
    const body = { partyId: userDetails.userId, product:userDetails.product };
    return this.baseClient.post<any>(`/user/emailExpiryResentInviteCC`,body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        return of(err)
      })
    );
  }
}
