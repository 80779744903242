import { Component, ViewEncapsulation, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Context } from '../../../core/models/Context.model';
import { UserContexts } from "src/app/core/models/UserContexts.model";
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';

/** user context component to display user context details */
@Component({
  selector: 'app-user-context',
  templateUrl: './user-context.component.html',
  styleUrls: ['./user-context.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserContextComponent implements OnInit {
  @Input() userContexts: UserContexts;
  @Input() displayContextOption: boolean;
  @Input() selectedContext: Context;
  @Output() contextChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loggedOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  showLogout: boolean = true;
  /** user context constructor */
  constructor(
    private liveAnnouncer: LiveAnnouncer,
    private readonly cookieLogoSvc: ManageCookieLogoService
  ) {}

  /** Base init method */
  ngOnInit() {
    const getSSOValue = this.cookieLogoSvc.getCookie('car-ses-sso');
    if (getSSOValue === 'true') {
      this.showLogout = false;
    }
  }

  changeContext() {
    this.contextChange.emit(true);
  }

  logout() {
    this.loggedOut.emit(true);
    this.cookieLogoSvc.removeCookies(['car-ses-sso'])
  }

  announceContext(): void {
   this.liveAnnouncer.announce(`${this.selectedContext.firstName} ${this.selectedContext.clientLegalName} ${this.selectedContext.clientNo}`);
  }

}
