import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DelegateLegalAggreementService {

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(private readonly logSvc: RemoteLoggingService,
    private readonly appConfig: AppConfigService,
    protected baseClient: BaseClientService, http: HttpClient, private readonly errorHandler: HttpErrorHandlerService, config: ApiConfigService) {
  }

  getDelegateConsentFlag(partyId: string, actionReferenceId: number): Observable<any> {
    let body: any = {}
    if (actionReferenceId && actionReferenceId !== 0)
    {
      body.partyId = partyId
      body.actionReferenceId = actionReferenceId.toString()
    }
    else {
      body.partyId = partyId
    }

    return this.baseClient.post<any>('/delegateConsent', body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  delegateOptOut(partyId: string, custBusnPartEmpId: string, actionReferenceId: number): Observable<any> {
    let body: any = {}
    if (actionReferenceId && actionReferenceId !== 0)
    {
      body.partyId = partyId
      body.custBusnPartEmpId = custBusnPartEmpId
      body.actionReferenceId = actionReferenceId.toString()
    }
    else {
      body.partyId = partyId
      body.custBusnPartEmpId = custBusnPartEmpId
    }
 
    return this.baseClient.post<any>('/declineInvite', body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  validateDelegateFederation(partyId: string, actionReferenceId: number): Observable<any> {
    return this.baseClient.getNoErrorHandler<any>(`/user/validateDelegateFedConsent/${partyId}/${actionReferenceId}`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        return of(err)
      })
    );
  }

  federateDelegate(partyId: string, BusnPartEmpId : string, actionReferenceId: number): Observable<any> {
    let body: any = {
      partyId : partyId,
      BusnPartEmpId : BusnPartEmpId,
      actionReferenceId : actionReferenceId.toString()
    }
    
    console.log('fedDelegateAcceptInvite', body);
    return this.baseClient.post<any>('/fedDelegateAcceptInvite', body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  updateConsentForm(partyId: string, status: boolean, actionReferenceId: number): Observable<any> {
    let body: any = {}
    if (actionReferenceId && actionReferenceId !== 0)
    {
      body.partyId = partyId
      body.OptOut = status
      body.actionReferenceId = actionReferenceId.toString()
    }
    else {
      body.partyId = partyId
      body.OptOut = status
    }

    return this.baseClient.post<any>('/updateConsentForm', body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

}
