import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DelegateLegalAggreementService } from 'src/app/core/services/delegate-legal-aggreement.service';
import { Subscription } from 'rxjs';
import { log } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../../core/models/User';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-delegate-legal-agreement',
  templateUrl: './delegate-legal-agreement.component.html',
  styleUrls: ['./delegate-legal-agreement.component.scss']
})

export class DelegateLegalAgreementComponent implements OnInit {
  logoConfig: any
  result:boolean = false;
  updateConsentFormResponse:any;
  delegateUser: any
  /** Used to inject MatStepper */
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  /** Input parameter step number received from parent component */
 @Input() step: number;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() delegateNotify: EventEmitter<number> = new EventEmitter<number>();
  @Output() radioPicked: EventEmitter<boolean> = new EventEmitter<any>();

  redirectToDelegateOptOut() {
    this.router.navigate(['/DelegateOptOut']);
  }

  /** subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  private readonly cookieService: CookieService;
  private readonly http: HttpClient

  /**
     * Base constructor
     * @param spinner to get NgxSpinner
     * @param fb Formbuilder variable
     * @param DelegateLegalSetupService DelegateLegalAggreementService
     // * @param activatedRoute To route to the particular location
     */

  form: FormGroup;
  @Input() userDetails: User;
  isDelegateFederated: number = 0;
  spinner: any;
  partyId: any;
  actionReferenceId: any;
  regLink: any;
  currentStep: any;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly location: Location,
    private readonly fb: FormBuilder,
    private readonly delegateLegalSetupService: DelegateLegalAggreementService,
  ) {

    this.logoConfig = { logo: 'cartus', type: 'main' }
    this.form = fb.group({
      Consent: ['Consent', Validators.required],
      OptOut: ['OptOut']
    });
  }

  ngOnInit(): void {
    this.regLink = this.activatedRoute.snapshot.url[1].path;
    this.partyId = this.userDetails.userId;
  }

  handleChange(e) {
    this.result = JSON.parse(e.target.value);
    this.radioPicked.emit(e.target.value);
  }
  onSubmit() {
    //below api call is to call b2bapi to updateConsentForm for both optout as well consent
        this.ngxSpinner.show();
        this.delegateLegalSetupService
          .updateConsentForm(this.partyId, this.result, this.actionReferenceId)
          .subscribe((response: any) => {
            if (response) {
              this.delegateUser = response.B2BAPIResponse;
              if (!this.result && this.delegateUser) {                
                  this.ngxSpinner.hide();
                  sessionStorage.setItem('isRedirectedfromConsentPage', '1');
                  this.delegateNotify.emit(1);
              } else {
                this.subscription.add(
                  this.delegateLegalSetupService.delegateOptOut(this.partyId, this.delegateUser.CustBusnPartEmpId, this.actionReferenceId).subscribe(response => {
                    if (!response || response.length === 0) {
                      console.error('Unrecognized userDetails');
                    }
                  }))
                this.ngxSpinner.hide();
                this.redirectToDelegateOptOut();   
              }
            } else {
              this.ngxSpinner.hide();
            }
          })
  }
}
