/** Title to display copyright*/
export const copyright = `© 2000-${new Date().getFullYear()} Cartus Corporation`;

export const ErrorMapping = {
    "Unknown social endpoint.": "Your Google account was not recognized.",
    "The client is not authorized to request an authorization code using this method.": "Your Google account was not recognized.",
    "The resource owner or authorization server denied the request.": "Your login request was denied.",
    "Unable to process the username transform.": "Your Google account was not recognized.",
    "User canceled the social login request.": "The login request was cancelled by the user.",
    "Social transaction expired.": "Your session has expired.",
    "Could not match social profile to an Okta user.": "Your Google account was not recognized.",
    "Error in linking or finding a linked user.": "Your Google account was not recognized.",
    "User creation was disabled.": "Your Google account was not recognized.",
    "User creation was denied by the callout service.": "Your Google account was not recognized.",
    "User linking was denied because the user is not in any of the specified groups.": "Your Google account was not recognized.",
    // eslint-disable-next-line max-len
    "The authorization server is currently unable to handle the request due to a temporary overloading or maintenance of the server.": "The server is unable to handle the login request. Please try again later.",
    "Could not fetch social profile.": "Your Google account was not recognized.",
    "Login failed.": "Login failed.",
    "default": "Login failed. There was an error processing your request. Please try again later."
}