import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { AppConfigService } from './app-config.service';
import { PartyAndOrganizationDetails } from '../models/party-and-organization-details.model';

@Injectable({
  providedIn: 'root'
})
export class CustFederationService {
  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(private readonly logSvc: RemoteLoggingService,
    private readonly appConfig: AppConfigService,
    protected baseClient: BaseClientService, http: HttpClient, private readonly errorHandler: HttpErrorHandlerService, config: ApiConfigService) {
  }
  federateUser(oldPartyId: string, newPartyId: string, userDetails: any, token: any): Observable<any> {
    const intUrl: any = this.appConfig.getConfig("integrationapi");
    const headers = new HttpHeaders({ 'Authorization': token, 'app-context': oldPartyId });
    const body = { oldPartyId, newPartyId, userDetails};
    return this.baseClient.postExtsion<any>(intUrl + 'v1/admin/b2bapi/FederateUser', body, headers, 'B2bApi call to federate user').pipe(
      map(r => r), catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }
  custDetails(token: any): Observable<Array<PartyAndOrganizationDetails>> {
    const headers = new HttpHeaders({ 'authorization': token });
    const params: HttpParams = new HttpParams();
    return this.baseClient.getWithOptions<PartyAndOrganizationDetails[]>("/user/appcontext", "get the springboard contexts list", params, headers).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: PartyAndOrganizationDetails[] = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }
}
