import { Injectable } from '@angular/core'
import { AppConfigService } from 'src/app/core/services/app-config.service'
import { CookieService } from 'ngx-cookie-service'
import { User } from 'src/app/core/models/User'

@Injectable({
  providedIn: 'root'
})

// This service handles the setting of logos and cookie settings and removals
export class ManageCookieLogoService {
  /*Available Sites*/
  authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'atlas', 'cartusOnline', 'atlasReporting', 'mobileApp']
  /* Okta session timeout */
  idleTimeoutMinutes = 55
  /** domain variable */
  domain: string = '.cartus.com'

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) { }

   /**
   * To set the session cookie in the browser
   * @param tokens - oktaResponse tokens
   * @param cookieName - cookie name
   */
  setSessionCookie(tokens: any): void {
    if (this.appConfig.getConfig('local')) {
      this.cookieService.set(
        'car-ses-tok',
        tokens.accessToken,
        undefined,
        undefined,
        undefined,
        undefined,
        'Lax') // Developer
    } else {
      this.cookieService.set(
        'car-ses-tok',
        tokens.accessToken,
        null, // We are relying on Okta session expiration
        '/',
        this.domain,
        true
      )
    }
  }

     /**
   * To set the session cookie in the browser
   * @param ssoValue - oktaResponse tokens has ssoValue
   * @param cookieName - cookie name
   */
     setSessionCookieForSSO(ssoValue: any): void {
      if (this.appConfig.getConfig('local')) {
        this.cookieService.set(
          'car-ses-sso',
          ssoValue,
          undefined,
          undefined,
          undefined,
          undefined,
          'Lax') // Developer
      } else {
        this.cookieService.set(
          'car-ses-sso',
          ssoValue,
          null, // We are relying on Okta session expiration
          '/',
          this.domain,
          true
        )
      }
    }

   /**
   * To remove specific cookies from the browser
   * @param cookieNames - array of cookie names
   */
  removeCookies(cookieNames: string[]): void {
    // work-around for deleting cookies since delete is broken in ngx-cookie-service
    // in the last version compatible with Angular 8
    const oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT')
    cookieNames.forEach(cookieName => {
      if (cookieName == 'car-ses-tok' || cookieName == "lastAction" || cookieName == 'car-ses-sso') {
        if (this.appConfig.getConfig('local')) {
          this.cookieService.set(
            cookieName,
            '',
            oldDate,
            '/',
            'localhost',
            false,
            'Lax'
            )
        } else {
          this.cookieService.set(
            cookieName,
            '',
            oldDate,
            '/',
            this.domain,
            false,
            'Lax'
            )
        }
      }
    })
  }

  /**
  * To remove all cookies from the browser except those that are whitelisted
  */
  removeAllCookies(): void {
    // work-around for deleting cookies since delete is broken in ngx-cookie-service
    // in the last version compatible with Angular 8
    const oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT')
    const domain = this.appConfig.getConfig('local') ? 'localhost' : this.domain
    const cookiesToRetain = [
      'cmapi_cookie_privacy',
      'cmapi_gtm_bl',
      'notice_behavior',
      'notice_gdpr_prefs',
      'notice_preferences'
    ]
    let cookiesToRemove: object = this.getAllCookies()
    if (Object.keys(cookiesToRemove || {}).length > 0) {
      for (const cookieName in cookiesToRemove) {
        if (cookiesToRemove.hasOwnProperty(cookieName) &&
        cookiesToRetain.indexOf(cookieName) === -1) {
          this.cookieService.set(cookieName, '', oldDate, '/', domain, false, 'Lax')
        }
      }
    }
  }

  /**
  * To get all cookies from the browser
  */
  getAllCookies(): object {
    return this.cookieService.getAll()
  }

  /**
   * To get a cookie
   * @param name - cookie name
   */
  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  /**
   * To set a cookie
   * @param name - cookie name
   * @param value - cookie value
   * @param expires - number of days until the cookies expires
   * @param path - cookie path
   * @param domain - cookie domain
   * @param secure - secure flag
   * @param sameSite - OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `None`
   */
  setCookie(name:string, value: string, expires: number, path: string, domain: string, secure: boolean, sameSite?: 'Lax' | 'None' | 'Strict'): void {
    if (this.appConfig.getConfig('local')) {
      this.cookieService.set(name, value, expires, path, 'localhost', true, sameSite);
    } else {
      this.cookieService.set(name, value, expires, path, domain, true, sameSite);
    }
  }

   /**
   * To set the logo for the template based on the referredU
   * @param referrerdURL - source app url
   * @return A response containing the Logo model (object)
   */
  setLogo(referredURL: string): string {

    // To hold the logo type
    let logo: string

    // Application URLs
    const movePro360URL = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|')
    const mobilifyHRURL = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|')
    const mobilifyUIURL = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|')
    const supplierPortalURL = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|')
    const benefitsBuilderURL = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|')
    const compensationServicesURL = this.appConfig.getConfig(this.authorizedLocations[5]).toString().split('|')
    const atlasURL = this.appConfig.getConfig(this.authorizedLocations[6]).toString().split('|')
    const cartusOnlineURL = this.appConfig.getConfig(this.authorizedLocations[7]).toString().split('|')
    const atlasReportingURL = this.appConfig.getConfig(this.authorizedLocations[8]).toString().split('|')
    const mobileAppURL = this.appConfig.getConfig(this.authorizedLocations[9]).toString().split('|')

    // /* For movePro360 Logo */
    if (referredURL === movePro360URL[0].replace(/(\/#|\/|#)$/, '') ||
         (movePro360URL.length > 1 && referredURL === movePro360URL[1].replace(/(\/#|\/|#)$/, ''))) {
           return 'movePro360'
        }
    // /* For benefitsBuilder Logo */
    if (!logo && (referredURL === benefitsBuilderURL[0].replace(/(\/#|\/|#)$/, '') ||
         (benefitsBuilderURL.length > 1 && referredURL === benefitsBuilderURL[1].replace(/(\/#|\/|#)$/, '')))) {
           return 'benefitsBuilder'
        }
    // /* For mobilifyHR Logo */
    if (!logo && (referredURL === mobilifyHRURL[0].replace(/(\/#|\/|#)$/, '') ||
         (mobilifyHRURL.length > 1 && referredURL === mobilifyHRURL[1].replace(/(\/#|\/|#)$/, '')))) {
           return 'mobilifyHR'
         }
    // /* For mobilifyUI Logo */
    if (!logo && (referredURL === mobilifyUIURL[0].replace(/(\/#|\/|#)$/, '') ||
        (mobilifyUIURL.length > 1 && referredURL === mobilifyUIURL[1].replace(/(\/#|\/|#)$/, '')))) {
          return 'mobilifyUI'
        }
    // /* For supplierPortal Logo */
    if (!logo && (referredURL === supplierPortalURL[0].replace(/(\/#|\/|#)$/, '')) ||
         (supplierPortalURL.length > 1 && referredURL === supplierPortalURL[1].replace(/(\/#|\/|#)$/, ''))) {
           return 'supplierPortal'
        }
    // /* For compensationServices Logo */
    if (!logo && (referredURL === compensationServicesURL[0].replace(/(\/#|\/|#)$/, '')) ||
    (compensationServicesURL.length > 1 && referredURL === compensationServicesURL[1].replace(/(\/#|\/|#)$/, ''))) {
      return 'compensationServices'
        }
    // /* For atlas Logo */
    if (!logo && (referredURL === atlasURL[0].replace(/(\/#|\/|#)$/, '')) ||
    (atlasURL.length > 1 && referredURL === atlasURL[1].replace(/(\/#|\/|#)$/, ''))) {
      return 'cartusLogo'
        }
    // /* For cartusOnline Logo */
    if (!logo && (referredURL === cartusOnlineURL[0].replace(/(\/#|\/|#)$/, '')) ||
    (cartusOnlineURL.length > 1 && referredURL === cartusOnlineURL[1].replace(/(\/#|\/|#)$/, ''))) {
      return 'cartusLogo'
        }
    // /* For atlasReporting Logo */
    if (!logo && (referredURL === atlasReportingURL[0].replace(/(\/#|\/|#)$/, '')) ||
    (atlasReportingURL.length > 1 && referredURL === atlasReportingURL[1].replace(/(\/#|\/|#)$/, ''))) {
      return 'cartusLogo'
        }
    // /* For mobileApp Logo */
    if (!logo && (referredURL === mobileAppURL[0].replace(/(\/#|\/|#)$/, '')) ||
    (mobileAppURL.length > 1 && referredURL === mobileAppURL[1].replace(/(\/#|\/|#)$/, ''))) {
      return 'cartusLogo'
        }

    // /* For Cartus Logo - used for springboard when no referrer */
    if (!logo && referredURL === '') {
      return 'cartus'
    }

  }

   /**
   * To set the logo for the template based on userDetails
   * @param userDetails - User Model object
   * @return Logo string
   *    */
  setLogoFromUser(userDetails: User): string {
    if (userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
      return 'mobilifyHR'
    } else if (userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
      return "movePro360"
    } else if (userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
      return 'benefitsBuilder'
    } else if (userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
      return 'compensationServices'
    } else if (userDetails.product === 'CartusOnline') {
      return 'cartusLogo'
    } else if (userDetails.product === 'Atlas') {
      return 'cartusLogo'
    }
  }

}
