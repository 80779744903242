import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
// import { PersistenceService, StorageType } from 'angular-persistence';
import { User } from '../../../../core/models/User';
import { ManageUserSharedService } from '../../../../core/services/manage-user-shared.service';

/** Class for  SelfRegistrationSuccess */
@Component({
  selector: 'app-self-registration-success',
  templateUrl: './self-registration-success.component.html',
  styleUrls: ['./self-registration-success.component.scss']
})
export class SelfRegistrationSuccessComponent implements OnInit, AfterViewInit {
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /**Input parameter custFed received from parent */
  @Input() custFed:number;
  /** string constants to be displayed in self registration success page */
  templateString={
     LOGIN_BTN: '',
    CONGRATS_TXT: '',
    ACCOUNT_CREATED_TXT: ''
  }  
  /**
   * Base constructor
   * @param router for route navigations
   * @param manageMoveSharedService ManageUserSharedService
   */
  constructor(
    private readonly router: Router,
    // private readonly persistenceService: PersistenceService,
    private readonly manageMoveSharedService: ManageUserSharedService
  ) { }

  ngOnInit() {
    this.manageMoveSharedService.updateData(this.userDetails);
    if (this.step || this.custFed ) { 
      switch (this.custFed || this.step) {
        case 2:
        case 0: {
          this.templateString = {
            LOGIN_BTN: 'Log In',
            CONGRATS_TXT: 'Congratulations!',
            ACCOUNT_CREATED_TXT: 'Your account has been created successfully'
          };
          break;
        }
        case 1: {
          this.templateString = {
            LOGIN_BTN: 'Log In',
            CONGRATS_TXT: 'Congratulations!',
            ACCOUNT_CREATED_TXT: 'Your account has been Linked successfully'
          };
          break;
        }
      }
    }
  }

  /** Store the current step in session */
  ngAfterViewInit(): void {
    // this.persistenceService.set('currentStep', JSON.stringify(this.step),
    //   { type: StorageType.SESSION });
    sessionStorage.setItem('currentStep', JSON.stringify(this.step));
  }

  /** Redirects to Login Page */
  redirectLogin() {
    sessionStorage.clear()
    this.router.navigate(['login']);
  }
}
