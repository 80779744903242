<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxFlex="100%" fxLayout fxLayout.xs="column" fxLayout.sm="column">
  <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
  <div fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between">

    <mat-card-content>
      <app-logo [logoConfig]="logoConfig"></app-logo>
      <div class="heading" fxLayoutAlign="center">
        <strong>Delegate Legal Agreement</strong>
      </div>
      <br>
      <div class="text1" fxLayoutAlign="center">
        Thank you for your response.
      </div>
      <br>
      <div class="text2" fxLayoutAlign="center">
        An Email with your decision will be sent to the person who invited <br>you to be their Delegate.
      </div>
    </mat-card-content>
  </div>
</div>