import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-extern-route',
  templateUrl: './extern-route.component.html',
  styleUrls: ['./extern-route.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExternRouteComponent {

  constructor() { }

}
