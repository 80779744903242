<div fxLayout="row" class="snack-content">
  <div fxLayout="column" class="sent">{{data}}</div>

  <div class="snack-icons">
    <div class="spinner-container">
      <div class="loader"></div>
    </div>
    <mat-icon (click)="dismiss()" id="dismiss" class="loader-close-icon">close</mat-icon>
  </div>
</div>
