<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxFlex="100%" fxFlexFill fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center">
    <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
    <div fxFlex.gt-sm="50" class="main-content">
        <div class="update-password-success-container panel-bg">
            <div>
                <h2 class="clean">{{templateString.UPDATE_STATUS}}</h2>
                <p class="clean">{{templateString.UPDATE_INFO}}</p>
                <div class="login-contain-btn btn-align-center" fxLayout="row" fxLayoutAlign="center center"
                    fxLayout.xs="column" fxLayoutGap="16px" fxLayoutGap.xs="0">
                    <button type="submit" (click)="redirectToSelectedProduct('mobilifyHR')">
                        {{mobilifyHRProductName}}
                    </button>
                    <button type="submit" (click)="redirectToSelectedProduct('movePro360')">
                        {{movePro360ProductName}}
                    </button>
                    <button type="submit" (click)="redirectToSelectedProduct('benefitsBuilder')">
                        {{benefitsBuilderProductName}}
                    </button>
                    <button type="submit" (click)="redirectToSelectedProduct('atlas')">
                        {{atlasProductName}}
                    </button>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>

