<div *ngIf="logoConfig && logoConfig.logo === 'mobilifyHR' && logoConfig.type === 'main'" class="logo" fxLayoutAlign="center center">
    <img id="mobilifyHRLogo" src="../../../../assets/imgs/logo-mobilify-stacked.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'mobilifyHR' && logoConfig.type === 'alt'" class="logo" fxLayoutAlign="center center">
    <img id="mobilifyHRLogo" src="../../../../assets/imgs/Mobilify-Logo-Horizontal-color.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'mobilifyUI' && logoConfig.type === 'main'" class="logo" fxLayoutAlign="center center">
  <img id="mobilifyUILogo" src="../../../../assets/imgs/logo-mobilify-stacked.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'mobilifyUI' && logoConfig.type === 'alt'" class="logo" fxLayoutAlign="center center">
  <img id="mobilifyUILogo" src="../../../../assets/imgs/Mobilify-Logo-Horizontal-color.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'benefitsBuilder'" class="logo" fxLayoutAlign="center center">
    <img id="benefitsBuilderLogo" src="../../../../assets/imgs/Benefits Builder logo.svg" alt="Benefits Builder">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'movePro360' && logoConfig.type === 'main'" class="logo" fxLayoutAlign="center center">
    <img id="movePro360Logo" src="../../../../assets/imgs/MovePro360-Color.svg" alt="MovePro360">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'movePro360' && logoConfig.type === 'alt'" class="logo movePro header-logo-movePro360" fxLayoutAlign="center center">
    <img id="movePro360Logo" src="../../../../assets/imgs/MovePro360-Color.svg" alt="MovePro360">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'supplierPortal')" class="logo" fxLayoutAlign="center center">
    <img id="supplierPortalLogo" class="supplierImg" src="../../../../assets/imgs/cartus-new-logo.svg" alt="Supplier Portal">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'atlas')" class="logo" fxLayoutAlign="center center">
    <img id ="cartusLogo" class="cartusImg" src="../../../../assets/imgs/cartus-new-logo.svg" alt="Cartus">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'cartus')" class="logo" fxLayoutAlign="center center">
    <img id="cartusLogo" class="cartusImg" src="../../../../assets/imgs/cartus-new-logo.svg" alt="Cartus">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'compensationServices')" class="compensation-logo logo" fxLayoutAlign="center center">
    <img id="compensationServicesLogo" class="compensationImg"  src="../../../../assets/imgs/springboard/compensationservicesDummy.svg" alt="Compensation Services">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'cartusLogo')" class="logo" fxLayoutAlign="center center">
    <img id="cartusLogo" class="cartusImg" src="../../../../assets/imgs/cartus-new-logo.svg" alt="Cartus">
</div>
