import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { SpringboardApps } from "src/app/public/models/springboardApps.model";
import { PartyAndOrganizationDetails } from "../models/party-and-organization-details.model";
import { PartyRoles, Capability, UserCapabilities } from "../models/PartyRoles";
import { AppConfigService } from "./app-config.service";
import { BaseClientService } from "./base-client.service";
import { RemoteLoggingService } from "./remote-logging.service";

@Injectable({
  providedIn: "root",
})
export class SpringboardSetupService {
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) {}

  /**
   * returns an array of PartyAndOrganizationDetails
   */
  getContexts(token: string): Observable<Array<PartyAndOrganizationDetails>> {
    const headers = new HttpHeaders({ 'authorization': token });
    const params: HttpParams = new HttpParams();
    return this.baseClient.getWithOptions<PartyAndOrganizationDetails[]>("/user/appcontext", "get the springboard contexts list", params, headers).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: PartyAndOrganizationDetails[] = null;
        this.logSvc.logError(err);
        return of(empty);
      })
      );
  }

  getCapabilities(token: string, partyId: string): Observable<UserCapabilities> {
    const headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
    let params: HttpParams = new HttpParams();
    params = params.append('return', 'capabilities');
    params = params.append('level', 'UI');
    return this.baseClient.getWithOptions<PartyRoles>(`/getUserRoleCapabilities/${partyId}`, "get the party capabilities", params, headers).pipe(
        map(r => {
          let capabilities: Capability[];
          if (r.body && r.body.partyRoles) {
            capabilities = JSON.parse(atob(r.body.partyRoles)).roleCapabilities;
          }
          return { partyId, capabilities };
        }), catchError((err, source) => {
        const empty: UserCapabilities = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /**
   * returns the applications available to the app-context
   */
  getApps(token: string, partyId: string, action?: string): Observable<SpringboardApps> {
    const headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
    let params: HttpParams = new HttpParams();
    if (action) params = params.append('action', action);
    return this.baseClient.getWithOptions<SpringboardApps>("/springboard", "get the springboard apps list", params, headers)
    .pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: SpringboardApps = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /**
   * sets a token for CartusOnline authentication
   */
  getColToken(token: string, partyId: string, selectAppContextFlag?: boolean, suppAppContext?: string): Observable<any> {
    const intUrl: any = this.appConfig.getConfig("integrationapi");
    const headers = new HttpHeaders({
      'authorization': token,
      'app-context': partyId,
      ...(suppAppContext && { 'supplier-app-context': suppAppContext })
    });
    let params: HttpParams = new HttpParams();
    if (selectAppContextFlag) params = params.append('selectAppContextFlag', selectAppContextFlag);
    return this.baseClient.getExt<any>(intUrl + 'v1/navigateToCOL', 'get the CartusOnline token', params, headers, true).pipe(
      map(r => r), catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /**
   * retrieve app url from config
   */
  getAppUrlFromName(appName: string): string {
    const url: string = <string>this.appConfig.getConfig(appName);
    return url;
  }

}
