import { Component, OnInit, OnDestroy, ViewEncapsulation, NgZone, AfterViewChecked } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { User } from '../../../core/models/User';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { OktaSignIn } from '@okta/okta-signin-widget';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';

import { ErrorMapping } from 'src/app/core/constants'
import { SpringboardSetupService } from "src/app/core/services/springboard-setup.service";
import { UserContexts } from "src/app/core/models/UserContexts.model";
import { Context } from "src/app/core/models/Context.model";
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';


/** Base Login component */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewChecked {
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Subscription for preference */
  prefSub: Subscription;
  /** Password display style, clear text or masked */
  hide: boolean;
  /*Available Sites*/
  authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'domain', 'cartusOnline', 'atlas'];
  /*To check redirected from authorised site or not*/
  authorisedSite = false;
  /*For the redirected URL */
  referredURL: string = '';
  /* For the Self registraion Login redirecting */
  selfRegLoginURL: string;
  /* Request Email Button Status*/
  requestEmailStatus = false;
  /* Okta session timeout */
  idleTimeoutMinutes = 55;
  /* Displays message to user (i.e. idle or session timeout) */
  message: string;
  /* Displays message to user (i.e. restrict user to access mobileapp ) */
  mobileAppMessage: string;
  /*Inactive user flag*/
  inactiveUser = false;
  /*Edge Chromium flag*/
  isEdgeChromium = false;
  /* Stores the route data */
  routeSub: any
  /* Okta widget */
  signInWidget: OktaSignIn;
  /* Okta widget config */
  signInWidgetConfig = {};
  /** errorHeadings object to store error headings */
  errorHeadings = {
    GENRAL_ERROR: "Please contact the Cartus Help Desk",
    MOBILEAPP_ACCESS_DENIED: "We're sorry, you do not have access to Cartus Mobile. Please verify your User ID and Password. Note: Repeated unsuccessful login attempts will lock the account.",
    MOBILEAPP_ACCESS_DENIED_DELEGATE: "We're sorry, delegate access is not available through Cartus Mobile. Please log in through CartusOnline."
  };
  /**
 * Initializes the value
 * @param router Instance Router
 */
  /**Base Constructor for Login Component
   * @param authSvc Authentication Service to authenticate the user details
   */
  /** Login Form.  Contains email and password fields, which are both required. */
  loginForm = this.fb.group({
    email: new UntypedFormControl('',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
      ])),
    password: ['', [Validators.required]]
  });
  templateString = {
    BNNER_TXT: `Technology That Moves You`,
    MOVEPRO360_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
      `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
      `needs, access predictive insights, and much more.`
  };
  /** Used to hold Candidate Move Data */
  userDetails: User;
  /** Used to hold logo config value */
  logoConfig: any;
  /** Used to determine if the Google sign in is displayed */
  showGoogleButton: boolean = false;
  /** Used to hold the help links to display when the Google sign in is displayed */
  googleHelpLinks: string[] = ['Help'];
  /** Used to store appId */
  appId: number;
  /** Used to store username */
  userId: string;
  //** GetLoginApp feture flag status */
  loginAppFlag: boolean = false;
  // used to hold the Anywhere feature flag status.
  anywhereAppFlag: boolean = false;
  // store the current username while login.
  checkUsername: string;
  // store the current emailID for forgot password/unlock account.
  checkAccountRec: string;
  /** userContexts to store the contexts available to the user */
  userContexts: UserContexts = { count: 0, contexts: [] };
  /** partiesRoles to store party and role combinations  */
  partiesRoles: [{ partyId?: string, roles?: string[] }] = [{}];
  /** accessToken variable to hold access token contained in header */
  accessToken: string;
  forgotPasswordURL: string;
  unlockAccountURL: string;

  constructor(
    private readonly authSvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly moveSharedService: ManageUserSharedService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly springboardSetupService: SpringboardSetupService,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private _ngZone: NgZone
  ) {
    /**
     * To get the login app feature flag
     */
    // this.getFeatureFlagValue();
    /** Added for Edge Browser compatiblity */
    if (!window['TextEncoder']) {
      window['TextEncoder'] = TextEncoder;
    }
  }

  /** Login Component's Init Function */
  async ngOnInit() {

    let routeData
    this.routeSub = this.route.data.subscribe(v => routeData = v);
    (routeData['googleIdp']) ? this.showGoogleButton = true : this.showGoogleButton = false
    this.isEdgeChromium = this.detectEdgeChromium();

    this.appId = (sessionStorage.getItem('appId')) ? parseInt(sessionStorage.getItem('appId').replace(/"/g, "")) : 0;

    // Handle message codes
    this.route.queryParams.subscribe(params => {
      if (params.code) {
        switch (params.code) { // These codes are being kept in alignment with CartusOnline for consistency
          case '4': {
            this.message = 'You have been logged out due to inactivity.';
            break;
          }
          case '5': {
            this.message = 'Your account is no longer active.';
            this.inactiveUser = true;
            break;
          }
          case '14': {
            this.message = 'Your session has expired.';
            break;
          }
          default: {
            this.message = null;
          }
        }
      }
      if (params.userId) {
        this.userId = params.userId;
      }

    });

    //get anywhereOkta feature flag status
    await this.getAnywhereFeatureFlagValue();

    // Deleting cookies on ngOnInit
    this.cookieLogoSvc.removeAllCookies();
    this.titleService.setTitle('Login');
    /* Region Google */
    let oktaUrl = String(this.appConfig.getConfig('oktaUrl'))
    let oktaId = String(this.appConfig.getConfig('oktaClientId'))

    if (this.anywhereAppFlag && !this.showGoogleButton) {
      oktaUrl = String(this.appConfig.getConfig('realogyOktaUrl'))
      oktaId = String(this.appConfig.getConfig('realogyOktaClientId'))
    }

    const oktaRedirectUrl = String(this.appConfig.getConfig('oktaRedirectUri'))
    const oktaIdpRedirectUrl = String(this.appConfig.getConfig('oktaIdpRedirectUrl'))
    const oktaGoolgleIdpId = String(this.appConfig.getConfig('oktaGoolgleIdpId'))
    /* End Region */
    this.spinner.show();
    // const googleIDPFeatureFlag = await this.getGoogleIDPFeatureFlag();
    this.loginAppFlag = await this.getFeatureFlagValue();

    if (this.anywhereAppFlag) {
      this.forgotPasswordURL = '/#/forgotpassword';
      this.unlockAccountURL = '/#/unlockaccount'
    } else {
      this.forgotPasswordURL = null;
      this.unlockAccountURL = null;
    }

    // Initialize Okta widget
    this.signInWidgetConfig = {
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': 'Enter your credentials to log in',
          'primaryauth.submit': 'Log In'
        }
      },
      features: {
        idpDisovery: true,
        rememberMe: false,
        selfServiceUnlock: true,
        multiOptionalFactorEnroll: true
      },
      baseUrl: oktaUrl.split('/oauth2')[0], // Derive from issuer
      clientId: oktaId,
      redirectUri: this.showGoogleButton ? oktaIdpRedirectUrl : oktaRedirectUrl,
      useInteractionCodeFlow: false,
      useClassicEngine: true,
      username: this.userId,
      authParams: {
        issuer: oktaUrl,
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile']
      },
      helpLinks: {
        help: '/#/contactUs',
        forgotPassword: this.showGoogleButton ? '/#/contactUs' : this.forgotPasswordURL,
        unlock: this.showGoogleButton ? '/#/contactUs' : this.unlockAccountURL
      },
      idps: this.showGoogleButton ? [
        { type: 'GOOGLE', id: oktaGoolgleIdpId }
      ] : null
    };
    this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
    /* Region Google IDP */
    this.signInWidget.on('ready', () => {
      if (this.showGoogleButton) {
        const widgetFormEl = document.querySelector('form');
        const widgetDividerEl: HTMLElement = document.querySelector('.auth-divider');
        const loginButton: HTMLElement = document.querySelector('.social-auth-google-button');
        const helpLinkEls = document.querySelectorAll('#help-links-container li') as NodeList;
        if (widgetFormEl) {
          widgetFormEl.style.display = 'none';
          widgetFormEl.style.visibility = 'hidden';
        }
        if (widgetDividerEl) {
          widgetDividerEl.style.display = 'none';
          widgetDividerEl.style.visibility = 'hidden';
        }
        if (loginButton && !loginButton.hasAttribute('clickevent')) {
          loginButton.setAttribute('clickevent', '');
          loginButton.addEventListener('click', () => { this.message = null });
        }
        if (helpLinkEls.length > 0) {
          helpLinkEls.forEach((helpLinkEl: HTMLElement) => {
            const displayLink = this.googleHelpLinks.includes(helpLinkEl.textContent);
            helpLinkEl.style.display = displayLink ? 'list-item' : 'none';
            helpLinkEl.style.visibility = displayLink ? 'visible' : 'hidden';
          });
        }
      }
    });
    /* End Region */
    /* Region Okta Account Creation Error */
    this.signInWidget.on('afterError', (context: any, error: any) => {

      if (this.showGoogleButton
        && context.controller && context.controller === 'primary-auth'
        && error.name && error.name === 'OAUTH_ERROR'
        && error.message) {
        this.message = this.getUserFriendlyMessage(error.message)
      }
    });

    this.signInWidget.on('afterRender', () => {
      if (document.getElementById('okta-signin-username').getAttribute('disabled')) {
        document.getElementById('okta-signin-username').removeAttribute('disabled');
      }
    })

    /* End Region */
    this.signInWidget.renderEl(
      { el: '#widget' },
      (res: any) => {
        this.onLoginResponse(res);
      },
      (err: any) => {
        console.error(err);
      }
    );

    // Set logo based on referrer
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      this.referredURL = val ? val.replace(/(\/#|\/|#)$/, '') : '';
      const res = this.cookieLogoSvc.setLogo(this.referredURL)
      if (res) {
        this.logoConfig = { logo: res, type: 'main' }
      }
    });

    this.moveSharedService.loginUserDetails.subscribe(loginUserDetails => {
      this.userDetails = loginUserDetails;
      if (!!this.userDetails && !!this.userDetails.userId && this.userDetails.userId.length > 0) {
        switch (this.userDetails.product) {
          case 'Alpha': // Non-Springboard Product Name is still returned by the API
            this.logoConfig = { logo: 'mobilifyHR', type: 'main' }
            break
          case 'MovePro': { // Non-Springboard Product Name is still returned by the API
            if (this.userDetails.systemOfOrigin === 'Traditional') {
              this.logoConfig = { logo: 'cartus', type: 'main' }
            } else {
              this.logoConfig = { logo: 'movePro360', type: 'main' }
            }
            break;
          }
          case 'BenefitsBuilder': // Non-Springboard Product Name is still returned by the API
            this.logoConfig = { logo: 'benefitsBuilder', type: 'main' }
            break
          case 'SupplierPortal': // Non-Springboard Product Name is still returned by the API
            this.logoConfig = { logo: 'supplierPortal', type: 'login' }
            break
          case 'CompensationServices': // Non-Springboard Product Name is still returned by the API
            this.logoConfig = { logo: 'compensationServices', type: 'login' }
            break
          case 'CartusOnline':
            this.logoConfig = { logo: 'cartusLogo', type: 'login' }
            break
          case 'Atlas':
            this.logoConfig = { logo: 'cartusLogo', type: 'login' }
            break
          default:
            break
        }
      }
    });

    // SSO check
    // No SSO in local mode
    if (!this.appConfig.getConfig('local')) {
      this.authSvc.trySso().then((tokenOrTokens: any) => {
        if (tokenOrTokens && tokenOrTokens.tokens && tokenOrTokens.tokens.accessToken) {
          // SSO authenticated
          this.cookieLogoSvc.setSessionCookie(tokenOrTokens.tokens.accessToken);
          this.subscription.add(
            this.authSvc
              .resetPasswordAttempts({
                "Authorization": tokenOrTokens.tokens.accessToken.accessToken
              }).subscribe(() => {
                // SSO authenticated - navigate to springboard
                this._ngZone.run(() => {
                  this.router.navigate(['/springboard']);
                });
              }));
        } else {
          this.spinner.hide(); // Not SSO authenticated
        }
      });
    } else {
      this.spinner.hide(); // No SSO Check
    }

    if (this.userId) {
      this.doCheck();
    }
  }

  /**
     * To get the login app feature flag
     */
  public async getAnywhereFeatureFlagValue() {
    this.anywhereAppFlag = await this.featureFlagService.getAnywhereApp('Enable_Anywhere_OKTA');
    console.log('AnywhereAppFlag status : ' + this.anywhereAppFlag)
  }

  // after every change in user name input field.
  ngAfterViewChecked() {
    if (this.anywhereAppFlag) {
      // checking changes on okta-signin-username input field.
      const userNameInput = document.querySelector("#okta-signin-username")
      if (userNameInput) {
        userNameInput.addEventListener("blur", event => {
          this.doCheck();
        });
      }
    }
  }

  // get all the internal email domains from config.
  emailDomains = String(this.appConfig.getConfig('ANYWHERE_OKTA_DOMAINS'));

  //to get email domain and initialized re-render signin widget while login.
  doCheck() {
    let loginEl: any;
    loginEl = document.querySelector("#okta-signin-username")
    if (!this.checkUsername && this.userId) {
      loginEl = { value: this.userId };
    }

    if (loginEl !== null && loginEl && loginEl?.value !== this.checkUsername && loginEl?.value !== "") {
      this.checkUsername = loginEl?.value
      // To get the email domain from user input.
      const domainName = this.getEmailDomain(this.checkUsername);
      console.log('Domain Name : ' + domainName)
      if (domainName) {
        if (this.emailDomains.toLocaleLowerCase().includes(domainName.toLocaleLowerCase())) {
          // to re render the signin widget with ANYWHERE Okta
          console.log('Initializing signin widget with ANYWHERE OKTA Credentials.')
          this.reRenderOktaWidget('ANYWHERE')
        } else {
          // to re render the signin widget with Cartus Okta
          console.log('Initializing signin widget with CARTUS OKTA Credentials.')
          this.reRenderOktaWidget('CARTUS')
        }
      }
    }
  }

  // To get the email domain from user input.
  getEmailDomain(email: string) {
    const domainRegex = /@([\w-]+(\.[\w-]+)+)$/;
    const match = domainRegex.exec(email);
    if (match && match[1]) {
      return match[1]
    } else {
      return null;
    }
  }

  // to re render the signin widget
  reRenderOktaWidget(input: string) {
    this.signInWidget.remove()
    let OktaUrl = String(this.appConfig.getConfig('realogyOktaUrl'))
    let OktaId = String(this.appConfig.getConfig('realogyOktaClientId'))
    let oktaIdpRedirectUrl = String(this.appConfig.getConfig('oktaIdpRedirectUrl'))
    let oktaRedirectUrl = String(this.appConfig.getConfig('oktaRedirectUri'))
    let oktaGoolgleIdpId = String(this.appConfig.getConfig('oktaGoolgleIdpId'))
    if (input === 'CARTUS') {
      OktaUrl = String(this.appConfig.getConfig('oktaUrl'))
      OktaId = String(this.appConfig.getConfig('oktaClientId'))
    }
    this.signInWidgetConfig = {
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': 'Enter your credentials to log in',
          'primaryauth.submit': 'Log In'
        }
      },
      features: {
        idpDisovery: true,
        rememberMe: false,
        selfServiceUnlock: true,
        multiOptionalFactorEnroll: true
      },
      baseUrl: OktaUrl.split('/oauth2')[0], // Derive from issuer
      clientId: OktaId,
      redirectUri: this.showGoogleButton ? oktaIdpRedirectUrl : oktaRedirectUrl,
      useInteractionCodeFlow: false,
      useClassicEngine: true,
      username: this.checkUsername,
      authParams: {
        issuer: OktaUrl,
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile']
      },
      helpLinks: {
        help: '/#/contactUs',
        forgotPassword: this.showGoogleButton ? '/#/contactUs' : this.forgotPasswordURL,
        unlock: this.showGoogleButton ? '/#/contactUs' : this.unlockAccountURL
      },
      idps: this.showGoogleButton ? [
        { type: 'GOOGLE', id: oktaGoolgleIdpId }
      ] : null
    };

    this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
    this.signInWidget.on('ready', () => {
      if (this.showGoogleButton) {
        const widgetFormEl = document.querySelector('form');
        const widgetDividerEl: HTMLElement = document.querySelector('.auth-divider');
        const loginButton: HTMLElement = document.querySelector('.social-auth-google-button');
        const helpLinkEls = document.querySelectorAll('#help-links-container li') as NodeList;
        if (widgetFormEl) {
          widgetFormEl.style.display = 'none';
          widgetFormEl.style.visibility = 'hidden';
        }
        if (widgetDividerEl) {
          widgetDividerEl.style.display = 'none';
          widgetDividerEl.style.visibility = 'hidden';
        }
        if (loginButton && !loginButton.hasAttribute('clickevent')) {
          loginButton.setAttribute('clickevent', '');
          loginButton.addEventListener('click', () => { this.message = null });
        }
        if (helpLinkEls.length > 0) {
          helpLinkEls.forEach((helpLinkEl: HTMLElement) => {
            const displayLink = this.googleHelpLinks.includes(helpLinkEl.textContent);
            helpLinkEl.style.display = displayLink ? 'list-item' : 'none';
            helpLinkEl.style.visibility = displayLink ? 'visible' : 'hidden';
          });
        }
      }
    });
    /* End Region */
    /* Region Okta Account Creation Error */
    this.signInWidget.on('afterError', (context: any, error: any) => {
      if (this.showGoogleButton
        && context.controller && context.controller === 'primary-auth'
        && error.name && error.name === 'OAUTH_ERROR'
        && error.message) {
        this.message = this.getUserFriendlyMessage(error.message)
      }
    });
    /* End Region */
    this.signInWidget.renderEl(
      { el: '#widget' },
      (res: any) => {
        this.onLoginResponse(res);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  /**
     * To get the GoogleIDP feature flag
     */
  // public async getGoogleIDPFeatureFlag() {
  //   return await this.featureFlagService.getgoogleIDPFeatureFlag();
  // }

  public async getFeatureFlagValue() {
    return await this.featureFlagService.getLoginApp('enable-login-app');
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.signInWidget) {
      this.signInWidget.remove();
    }
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
  detectEdgeChromium(): boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    return agent.indexOf('edg') > -1 ? true : false;
  }

  onLoginResponse(res: any): void {
    if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
      // TBD
    }
    if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
      // TBD
    }
    if (res.status === 'PASSWORD_EXPIRED') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.router.navigate(['/updatePassword']);
      }
    }
    if (res.status === 'LOCKED_OUT') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.requestEmailStatus = true;
      }
    }
    if (res.status === 'SUCCESS') {
      // SSO Code for later - Do not remove
      // // OIDC
      // if (res.type === 'SESSION_STEP_UP') {
      //   console.log(res.user);
      //   console.log('Target resource url: ' + res.stepUp.url);
      // res.stepUp.finish();
      //   return;
      // } else {
      //   console.log(res.user);
      //   res.session.setCookieAndRedirect('https://acme.com/app');
      //   return;
      // }
      // // Not OIDC
      // console.log(res.claims);
      // this.signInWidget.tokenManager.add('my_id_token', res);
      // this.signInWidget.tokenManager.add('my_id_token', res[0]);
      // this.signInWidget.tokenManager.add('my_access_token', res[1]);
      // return;      
      if (res && res.tokens && res.tokens.idToken && res.tokens.idToken.claims && res.tokens.idToken.claims.sub) {
        this.accessToken = res.tokens.accessToken.accessToken;
        if (res.tokens.accessToken.claims.hasOwnProperty('ssoValue')) {
          this.cookieLogoSvc.setSessionCookieForSSO(true);
        } else {
          this.cookieLogoSvc.setSessionCookieForSSO(false);
        }
        this.subscription.add(
          this.authSvc
            .resetPasswordAttempts({
              "Authorization": res.tokens.accessToken.accessToken
            })
            .subscribe((async () => {
              try {
                this.cookieLogoSvc.setSessionCookie(res.tokens.accessToken); // clear attempts
                // Store the tokens in the token manager so it can signout and revoke
                this.authSvc.authClient.tokenManager.setTokens(
                  {
                    accessToken: res.tokens.accessToken,
                    idToken: res.tokens.idToken
                  }
                );
                if (this.userDetails) {
                  let urls = [];
                  if (this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
                    if (this.userDetails.roleName === 'candidate') {
                      urls = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|');
                    } else if (this.userDetails.roleName === 'supplier-contact') {
                      urls = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|');
                    } else {
                      urls = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|');
                    }
                  } else if (this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
                    if (this.userDetails.systemOfOrigin === 'Traditional') {
                      urls = this.appConfig.getConfig(this.authorizedLocations[6]).toString().split('|');
                    } else {
                      urls = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|');
                    }
                  } else if (this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
                    urls = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|');
                  } else if (this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API */
                    urls = this.appConfig.getConfig(this.authorizedLocations[5]).toString().split('|');
                  }
                  else if (this.userDetails.product === 'CartusOnline') { // Non-Springboard Product Name is still returned by the API */
                    urls = this.appConfig.getConfig(this.authorizedLocations[7]).toString().split('|');
                    this._ngZone.run(() => {
                      this.router.navigate(['/springboard']);
                    });
                  }
                  else if (this.userDetails.product === 'Atlas') { // Non-Springboard Product Name is still returned by the API */
                    urls = this.appConfig.getConfig(this.authorizedLocations[8]).toString().split('|');
                    this._ngZone.run(() => {
                      this.router.navigate(['/springboard']);
                    });
                  }
                  if (urls.length > 0) {
                    this.selfRegLoginURL = urls[0];
                    this.spinner.hide();
                    if ((this.userDetails.product !== 'Atlas') && (this.userDetails.product !== 'CartusOnline'))
                      this.router.navigate(['/externalRedirect', { externalUrl: this.selfRegLoginURL }], {
                        skipLocationChange: true,
                      });
                  } else { // Unrecognized userDetails
                    console.error('Unrecognized userDetails');
                  }
                } else { // No userDetails - check mobileApp access and redirect  

                  if (this.appId === 10 && this.loginAppFlag) {
                    await this.getUserContexts()
                    if (this.userContexts.count > 0) {
                      this.checkRolesForCartusMobile()
                    } else {
                      this._ngZone.run(() => {
                        this.router.navigate(['/springboard']);
                      });
                    }
                  } else {
                    this._ngZone.run(() => {
                      this.router.navigate(['/springboard']);
                    });
                  }

                }
              } catch (error) {
                console.log("Error raised from onLoginResponse", error)
              }
            })
            ))
      }
    }
    this.spinner.hide();
  }

  /** getUserContexts method to get all party and organization details associated with the partyId contained in the access token */
  async getUserContexts() {
    await firstValueFrom(this.springboardSetupService.getContexts(this.accessToken))
      .then(async partyAndOrganizationDetails => {
        this.buildContexts(partyAndOrganizationDetails)
        this.buildPartiesRoles(partyAndOrganizationDetails)

      });
  }

  buildPartiesRoles(partyAndOrganizationDetails) {
    const now = new Date();
    partyAndOrganizationDetails.forEach((item, index) => {
      if (item.party) {
        this.partiesRoles[index] = { partyId: item.party._id, roles: [] };
        if (item.party.roles) {
          item.party.roles.forEach(role => {
            if ((!role.fromDate || (role.fromDate && now > new Date(role.fromDate))) && (!role.toDate || (role.toDate && now < new Date(role.toDate)))) {
              this.partiesRoles[index].roles.push(role.name);
            }
          });
        }
      }
    });
  }

  buildContexts(partyAndOrganizationDetails) {
    partyAndOrganizationDetails.forEach(item => {
      if (item.party) {
        let context: Context = {};
        context.partyId = item.party._id;
        context.firstName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 0
          ? item.party.currentName.names[0] : null;
        context.lastName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 1
          ? item.party.currentName.names[item.party.currentName.names.length - 1] : null;
        context.multiCurrencyFlag = false; // TODO: not in currently in payload, hardcoding to false as per discussion
        if (item.organizationDetails) {
          if (item.organizationDetails.entityName) {
            context.clientNo = item.organizationDetails.clientNumber;
            context.clientLegalName = item.organizationDetails.entityName;
          }
          if (item.organizationDetails.contactMechanisms && item.organizationDetails.contactMechanisms.length > 0) {
            const address = item.organizationDetails.contactMechanisms.filter(contactMechanism =>
              contactMechanism.contactType === "address" &&
              contactMechanism.usageType === 'business');
            if (address.length > 0) {
              context.clientAddrLine1 = address[0].addressLines[0] ? address[0].addressLines[0] : null;
              context.clientAddrLine2 = address[0].addressLines[1] ? address[0].addressLines[1] : null;
              context.clientCityName = address[0].city ? address[0].city : null;
              context.clientStProvName = address[0].state ? address[0].state : null;
              context.clientCountryName = address[0].country ? address[0].country : null;
              context.clientPostalCode = address[0].postalCode ? address[0].postalCode : null;
            }
          }
        }
        this.userContexts.contexts.push(context)
      }
    });
    this.userContexts.count = this.userContexts.contexts.length
  }

  async checkRolesForCartusMobile() {
    const partiesCount = this.partiesRoles.length;
    const delegateCount = this.partiesRoles.filter(party => party.roles.includes('delegate')).length;
    const legacysupplierCount = this.partiesRoles.filter(party => party.roles.includes('legacy-supplier-contact')).length;
    const transfereeCount = this.partiesRoles.filter(party => party.roles.includes('transferee')).length;
    const legacytransfereeCount = this.partiesRoles.filter(party => party.roles.includes('legacy-transferee')).length;
    const clientcontactCount = this.partiesRoles.filter(party => party.roles.includes('client-contact')).length;
    const clientcontactInitiatorCount = this.partiesRoles.filter(party => party.roles.includes('client-contact')).length;
    const employeeCount = this.partiesRoles.filter(party => party.roles.includes('cartus-employee')).length;

    if (delegateCount > 0) {
      this.mobileAppMessage = this.errorHeadings.MOBILEAPP_ACCESS_DENIED_DELEGATE;
      this.accessDeniedForMobile(this.mobileAppMessage)
    } else if (legacysupplierCount > 0) {
      this.mobileAppMessage = this.errorHeadings.MOBILEAPP_ACCESS_DENIED;
      this.accessDeniedForMobile(this.mobileAppMessage)
    } else {
      if (transfereeCount > 0 && partiesCount === employeeCount) {
        const transferee = this.partiesRoles.find(party => party.roles.includes('transferee'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === transferee.partyId))
      } else if (employeeCount > 0 && partiesCount === employeeCount) {
        const employee = this.partiesRoles.find(party => party.roles.includes('cartus-employee'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === employee.partyId))
      } else if (legacytransfereeCount > 0 && partiesCount === legacytransfereeCount) {
        const legacytransferee = this.partiesRoles.find(party => party.roles.includes('legacy-transferee'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === legacytransferee.partyId))
      } else if (clientcontactCount > 0 && partiesCount === clientcontactCount) {
        const clientcontact = this.partiesRoles.find(party => party.roles.includes('client-contact'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === clientcontact.partyId))
      } else if (clientcontactInitiatorCount > 0 && partiesCount === clientcontactInitiatorCount) {
        const clientcontactInitiator = this.partiesRoles.find(party => party.roles.includes('client-contact-initiator'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === clientcontactInitiator.partyId))
      } else if (clientcontactCount > 0 && partiesCount > 1) {
        const clientcontact = this.partiesRoles.find(party => party.roles.includes('client-contact'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === clientcontact.partyId))
      } else if (legacytransfereeCount > 0 && partiesCount > 1) {
        const legacytransferee = this.partiesRoles.find(party => party.roles.includes('legacy-transferee'));
        this.redirectToCartusMobile(this.userContexts.contexts.find(context => context.partyId === legacytransferee.partyId))
      } else {
        this.mobileAppMessage = this.errorHeadings.MOBILEAPP_ACCESS_DENIED;
        this.accessDeniedForMobile(this.mobileAppMessage)
      }
    }
  }

  accessDeniedForMobile(errorMsg: any) {
    this.cookieLogoSvc.removeAllCookies();

    let extras: any;
    extras = {
      queryParams: {
        appId: 10
      }
    };

    // Sign out of Okta
    this.authSvc.signOut();
    this.spinner.hide();

    this.signInWidget.remove();
    this.signInWidget.renderEl(
      { el: '#widget' },
      (res: any) => {
        this.onLoginResponse(res);
      },
      (err: any) => {
        console.error(err);
      }
    );

    this.signInWidget.on('afterRender', (context: any, error: any) => {
      const widgetFormEl = document.querySelector('.o-form-error-container');
      widgetFormEl.className += ' o-form-has-errors';
      widgetFormEl.innerHTML = `<div><div class="okta-form-infobox-error infobox infobox-error" role="alert"><span class="icon error-16"></span>
            <p>${errorMsg}</p></div></div>`;
    });


  }

  async redirectToCartusMobile(context: Context) {
    this.cookieLogoSvc.setCookie('app-context', context.partyId, 1, '/', '.cartus.com', true);
    sessionStorage.setItem("car-ses-con", context.partyId);
    sessionStorage.setItem("UserContext", JSON.stringify(context));
    await firstValueFrom(this.springboardSetupService.getColToken(this.accessToken, context.partyId))
    let referredURL: string;
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(referrer => {
      referredURL = referrer ? referrer : undefined;
    });
    if (referredURL && referredURL.indexOf("http") > -1) {
      this.titleService.setTitle("Loading...");
      this.router.navigate(["/externalRedirect", { externalUrl: referredURL }], {
        skipLocationChange: true,
      });
    }

  }

  public getUserFriendlyMessage(message: string): string {
    console.log("error", message)
    return ErrorMapping[message] || ErrorMapping['default']
  }
}
