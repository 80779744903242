import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { User } from '../../../../core/models/User';
// import { PersistenceService, StorageType } from 'angular-persistence';
import { Subscription } from 'rxjs';
import { UserRegister } from '../../../../core/models/UserRegister';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { AppConfigService } from 'src/app/core/services/app-config.service';

/** Class for  SelfRegistrationCreateUser */
@Component({
  selector: 'app-self-registration-create-user',
  templateUrl: './self-registration-createUser.component.html',
  styleUrls: ['./self-registration-createUser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationCreateUserComponent implements OnInit, AfterViewInit {
  /** variable for isCandidateOrTransferee */
  isCandidateorTransferee = true;
  public privacyPolicy: string;
  /** variable for formgroup */
  selfRegistrationSetupForm: UntypedFormGroup;
  /** variable to store user details */
  addUser: UserRegister = {} as UserRegister;
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Stores the strings used in the template */
  templateString = {
    FORM_TITLE: `Creating an account is quick and easy!`,
    PASSWORD_INSTRUCTION_TITLE: `Passwords must be at least 10 characters, and must contain the following details:`,
    PRIVACY_NOTICE_TXT: `By clicking the button below you are agreeing to our`,
    PRIVACY_NOTICE: `Privacy Policy`,
    EMAIL: 'Email',
    PASS: 'Password',
    CONFIRM_PASS: 'Confirm Password',
    CREATE_ACC_BTN: 'Create Account',
    expiredRegistration: 'This registration link has expired.  Please contact your Cartus Consultant for a new registration link.',
    duplicateEmail: 'This email cannot be used to create an account.  Please use a different email or contact Cartus.',
    apiError: 'We are unable to process your request at this time. Please try again later.',
    CHECKBOX_TEXT: 'Check if you already have a Cartus account and would like to link your accounts. You will be asked to enter your existing Cartus UserID and Password on the next page',
    CONTINUE_BTN: 'Continue'
  };
  /** Password instruction display string */
  passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
  /** Activates if the resolution is of a mobile device */
  responsiveView: boolean;
  // if true Create User Fields will be disable and Continue button enabled
  isExistingUser: boolean = false;
  // Detect window size
  @HostListener('window:resize')
  onresize() {
    this.getWindowSize();
  }
  //To get registration link for redirect
  registrationLink: string;
  //** GetLoginApp feture flag status */
  loginAppFlag: boolean = false;

  /**
 * Base constructor
 * @param fb UntypedFormBuilder
 * @param registrationSetupService SelfRegistrationSetupService
 * @param spinner to get NgxSpinner
 * @param snackBar MatSnackBar
 * @param logSvc RemoteLoggingService
 */
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private featureFlag: FeatureFlagService,
    // private readonly persistenceService: PersistenceService,
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly logSvc: RemoteLoggingService,
    private readonly appConfig: AppConfigService) { }

  /** To Initialize Component */
  ngOnInit() {
    this.featureFlag.getLoginApp('enable-login-app')
      .then(res => { this.loginAppFlag = res })
    this.registrationLink = this.activatedRoute.snapshot?.url[1]?.path;
    this.getWindowSize();
    this.createControl();
    this.privacyPolicy = String(this.appConfig.getConfig('privacyPolicy'));
  }

  /** To Create form Controls */
  createControl() {
    this.selfRegistrationSetupForm = this.fb.group({
      email: new UntypedFormControl(this.userDetails.emailAddress,
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9-]+[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,3}$')
        ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$'),
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*)(><_-{}]).{10,}$')
      ])),
      confirmpassword: ['', Validators.required]
    });
    if (this.userDetails.roleName !== 'candidate' && this.userDetails.roleName !== 'transferee' && this.userDetails.roleName !== 'legacy-transferee') {
      this.selfRegistrationSetupForm.controls['email'].disable();
      this.isCandidateorTransferee = false;
    }
    this.selfRegistrationSetupForm.controls['email'].markAsTouched();
  }

  /** Store the current step in session */
  ngAfterViewInit(): void {
    // this.persistenceService.set('currentStep', JSON.stringify(this.step),
    //   { type: StorageType.SESSION });
    sessionStorage.setItem('currentStep', JSON.stringify(this.step));
  }

  /** Redirect to Login */
  redirectToLogin() {
    this.router.navigate(['existing', this.registrationLink]);
  }

  /** To check whether both passwords match */
  checkPassword() {
    this.selfRegistrationSetupForm.get('password').value === this.selfRegistrationSetupForm.get('confirmpassword').value ?
      this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors(null) :
      this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors({
        notSame: true
      });
  }
  /** checks if password contains parts of username */
  checkForUsername() {
    const username = this.selfRegistrationSetupForm.get('email').value.split(/[^\w\s]|_/g);
    const password = this.selfRegistrationSetupForm.get('password').value.toLowerCase();
    for (const name of username) {
      if (name.length >= 4 && password.includes(name.toLowerCase())) {
        this.selfRegistrationSetupForm.controls['password'].setErrors({
          containsUsername: true
        });
        break;
      } else if (!this.selfRegistrationSetupForm.get('password').hasError('pattern') &&
        !this.selfRegistrationSetupForm.get('password').hasError('required')) {
        this.selfRegistrationSetupForm.controls['password'].setErrors(null);
      }
    }
  }

  /** To create user */
  createUser() {
    this.ngxSpinner.show();
    this.addUser.username = this.selfRegistrationSetupForm.controls['email'].value;
    this.addUser.password = this.selfRegistrationSetupForm.controls['password'].value;
    this.addUser.partyId = this.userDetails.userId;
    this.addUser.orderRequestId = this.userDetails.orderRequestId;
    this.addUser.product = this.userDetails.product;
    this.addUser.roleName = this.userDetails.roleName;
    this.addUser.systemOfOrigin = this.userDetails.systemOfOrigin;
    this.subscription.add(
      this.registrationSetupService
        .registerUser(this.addUser)
        .subscribe(response => {
          if (!!response) {
            let logError: boolean
            let message: string
            let snackbarMessage: string
            if (response.status && response.status === 400) { // 400 error
              if (response.error && response.error.message) {
                message = response.error.message.toLowerCase()
                if (message.includes('self registration has expired')) {
                  snackbarMessage = this.templateString.expiredRegistration
                } else if (message.includes('an object with this field already exists in the current organization')) {
                  snackbarMessage = this.templateString.duplicateEmail
                } else {
                  logError = true
                  snackbarMessage = this.templateString.apiError
                }
              }
            }

            if (response.status && response.status !== 400 && response.error) { // other error
              logError = true
              snackbarMessage = this.templateString.apiError
            }

            if (!!snackbarMessage) {
              this.ngxSpinner.hide();
              this.snackBar.open(
                snackbarMessage,
                undefined,
                { duration: 5000 }
              )
              if (!!logError) {
                this.logSvc.logError(response)
              }
            } else {
              this.ngxSpinner.hide()
              let currentStep
              currentStep = parseInt(sessionStorage.getItem('currentStep'), 10)
              this.notify.emit(currentStep)
            }
          }
        }
        )
    );
  }

  /**
   * To set error message to fields
   * @param fieldName - Field Name
   */
  getErrorMessage(fieldName): string | undefined {
    if (fieldName === 'EMAIL') {
      return this.selfRegistrationSetupForm.get('email').hasError('required')
        ? 'You must enter a User ID'
        : this.selfRegistrationSetupForm.get('email').hasError('pattern')
          ? 'User ID must be a valid email address'
          : '';
    }
    if (fieldName === 'PASSWORD') {
      return this.selfRegistrationSetupForm.get('password').hasError('required')
        ? 'You must enter a password'
        : this.selfRegistrationSetupForm.get('password').hasError('pattern')
          ? 'You must match password complexity rules'
          : this.selfRegistrationSetupForm.get('password').hasError('containsUsername')
            ? 'Password cannot contain part of email'
            : '';
    }
    if (fieldName === 'CONFIRMPASSWORD') {
      return this.selfRegistrationSetupForm.get('confirmpassword').hasError('required')
        ? 'You must enter a password'
        : '';
    }
  }

  /*Enable & Disable Create User Fields and Continue button */
  existingUserCheckBox(event) {
    this.isExistingUser = event.checked;
    if (this.isExistingUser) {
      this.selfRegistrationSetupForm.controls['email'].disable();
      this.selfRegistrationSetupForm.controls['password'].disable();
      this.selfRegistrationSetupForm.controls['confirmpassword'].disable();
    } else {
      if (this.isCandidateorTransferee) {
        this.selfRegistrationSetupForm.controls['email'].enable();
      }
      this.selfRegistrationSetupForm.controls['password'].enable();
      this.selfRegistrationSetupForm.controls['confirmpassword'].enable();
      this.checkPassword();

    }
  }

  /** To get window size and responsiveview */
  getWindowSize() {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 959) {
      this.responsiveView = true;
    } else {
      this.responsiveView = false;
    }
  }
}
