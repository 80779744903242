<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="privacy-notice" *ngIf="isDelegateFederated===0">
  <div class="main-content">
   
    <mat-card-content>
    </mat-card-content>
    <div class="heading" fxLayoutAlign="center">
        <b>Delegate Legal Agreement</b>
    </div>
    <br>
    <div>
      <div class="text description" fxLayoutAlign="center">
        <p class="top_para">Before you proceed to the Account Creation process, please read and <br> agree to the CartusOnline Delegate Agreement:</p>
      </div>
      <br>
      <div class="descriptionBox"  id="borderLine" fxLayoutAlign="center">
        <p class="description_text">I agree that I will use my authority as a delegate to act in the best interests of Cartus'customer and in accordance with the <a href="https://cartus.com/en/legal/terms-use/terms-use-usa/" target="_blank" rel="noopener noreferrer"><span style="color: rgb(0,154,223);">Terms of Use.</span></a></p>
      </div>
    </div>

  </div>

  <div class="radio-group">
  <form [formGroup]="form" fxLayoutAlign="center">
    <label class="radio_consent">
      <input  type="radio" name="test" value="false" checked="checked" (change)="handleChange($event)">
        <span><i>I Consent</i></span>
    </label>
    <label  class="radio_opt_out">
      <input  type="radio" name="test" value="true" (change)="handleChange($event)">
        <span><i>I Opt Out</i></span>
    </label>
 </form>
</div>
  
<br>
 
  <div class="delegate-submit-btn btn-align-center">
    <button class="button_submit" type="submit" (click)= "onSubmit()"  id="login">
        Submit
    </button>
  </div> 
  <div class="below_submit">

  </div>
</div>
<div *ngIf="isDelegateFederated === 1">
  <app-self-registration-success [custFed]="isDelegateFederated"></app-self-registration-success>
</div>
