import { Injectable, HostListener, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpringboardTimeoutService {

  /** Keeps all the data related to Timeout */
  public timeoutData = new BehaviorSubject<any>(null);
  public timeoutData$ = this.timeoutData.asObservable();

  constructor() {
  }
}
