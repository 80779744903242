import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { copyright } from 'src/app/core/constants';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsOfUseComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = copyright;
  /** Used to hold logo config value */
  logoConfig: any;

  constructor(
    private readonly titleService: Title,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Terms of Use');
    this.logoConfig = { logo: 'cartus', type: 'main' }
    this.spinner.show();
    setTimeout(() => { this.spinner.hide(); }, 2000 );
  }

}
