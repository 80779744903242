import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/User';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';

@Component({
  selector: 'app-duplicate-registration',
  templateUrl: './duplicate-registration.component.html',
  styleUrls: ['./duplicate-registration.component.scss']
})
export class DuplicateRegistrationComponent implements OnInit {
  templateString = {
    BANNER_TXT: `Technology That Moves You`,
    LOGIN_BTN: `MobilifyHR`
  };
  /** Variable to store the product name*/
  productName: string;
  /** Variable to store user Details */
  userDetails: User;
  /** variable to have static content text */
  contentTextPrefix: string;
  /** variable to have static content text */
  contentTextPostfix: string;
  /** Used to hold logo config value */
  logoConfig: any;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly moveSharedService: ManageUserSharedService,
    private readonly router: Router,
    private readonly cookieLogoSvc: ManageCookieLogoService) {
    this.contentTextPrefix = 'You have already registered for';
    this.contentTextPostfix = ', please click below to log in to your account:';
  }

  ngOnInit() {
    this.moveSharedService.loginUserDetails.subscribe(loginUserDetails => {
      this.userDetails = loginUserDetails;
      const val = this.cookieLogoSvc.setLogoFromUser(this.userDetails);
      this.logoConfig = { logo: val, type: 'alt' }
      if (this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('mobilifyHR_ProductName').toString();
      } else if (this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('movePro360_ProductName').toString();
      } else if (this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('benefitsBuilder_ProductName').toString();
      }  else if (this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('compensationServices_ProductName').toString();
      } else if (this.userDetails.product === 'Atlas') {
        this.productName = this.appConfig.getConfig('atlas_ProductName').toString();
      }

      this.templateString.LOGIN_BTN = this.productName;
    });
  }

   /**
   * To externally navigate to login
   * @param params - params
   */
  navigateToLogin(params): void {
    let extras: any;
    if (params && params.code) {
      extras = {
      }
      this.router.navigate(['login'], extras);
    }
  }

  redirect() {
    this.router.navigateByUrl('login');
  }

}
