import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Subscription, interval, Observable } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-springboard-timeout',
  templateUrl: './springboard-timeout.component.html',
  styleUrls: ['./springboard-timeout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SpringboardTimeoutComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter: number = 120;
  tick = 1000;
  timer$: Observable<number>;
  constructor(private ref : ChangeDetectorRef,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.countDown = interval(this.tick).subscribe (() => {
      --this.counter;
      if (this.counter === 0) this._snackBar.dismiss();
      this.ref.detectChanges();
    })

  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
    this.countDown = null;
  }

}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
