<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="consent_inclusive">
    <div id="consent_blackbar"></div>
    <div class="loginContainer FooterAlign" fxFlex="100%" fxLayout fxLayout.xs="column" fxLayout.sm="column">
        <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
        <div class="loginContentBlock" fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between">
            <mat-card class="loginDetailsBlock shadowNone" style="box-shadow: none;">
                <mat-card-content>
                    <app-logo [logoConfig]="logoConfig"></app-logo>
                    <div class="intro-message body-copy" ngClass.sm="med-margin">
                        <div *ngIf="logoConfig && logoConfig.logo === 'movePro360'">{{templateString.MOVEPRO360_TXT}}
                        </div>
                    </div>
                    <div id="reset-container">
                        <div id="reset-form">
                            <h2 class="o-form-head" *ngIf="!afterMailSent">Unlock account</h2>

                            <div *ngIf="afterError">
                                <div class="first-error-box">
                                    <div class="error-grid">
                                        <div class="error-alertF">
                                            <div style="margin-top: 10px;"></div>
                                            <div class="error-alert-icon">!</div>
                                        </div>
                                        <div class="error-alertL">
                                           {{errorMessage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 class="o-form-head" *ngIf="afterMailSent">Email sent!</h2>
                            <div *ngIf="isFormSubmitted && !username.trim()">
                                <div class="first-error-box">
                                    <div class="error-grid">
                                        <div class="error-alertF">
                                            <div style="margin-top: 10px;"></div>
                                            <div class="error-alert-icon">!</div>
                                        </div>
                                        <div class="error-alertL">
                                            We found some errors. Please review the form and make corrections.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="afterMailSent">

                                <p class="success-msg">Email has been sent to {{username}} with instructions on
                                    unlocking your account.</p>
                            </div>
                            <div id="reset-password-form">
                                <div class="form-group">
                                    <label for="username" *ngIf="!afterMailSent">Email or Username</label>
                                    <input type="text" name="username" id="username" autocomplete="username"
                                        *ngIf="!afterMailSent">

                                    <div id="error-text" class="error-message"
                                        *ngIf="isFormSubmitted && !username.trim()">
                                        <span class="error-alert-icon2">!</span>
                                        This field cannot be left blank
                                    </div>

                                    <button (click)="onUnlockAccount()" *ngIf="!afterMailSent"
                                        class="button button-primary button-wide email-button link-button">Send
                                        Email</button>

                                    <button *ngIf="afterMailSent"
                                        class="button button-primary button-wide email-button link-button"><a
                                            class="back-to-login" href="/#/login">Back to
                                            sign
                                            in</a></button>

                                </div>
                            </div>
                            <div class="auth-footer" *ngIf="!afterMailSent">
                                <a href="/#/login" class="link help js-back" id="back-link">Back to sign in</a>
                            </div>
                        </div>
                    </div>
                    <div class="message" *ngIf="message && !inactiveUser">{{message}}</div><br />
                    <div *ngIf="inactiveUser">
                        <div class="heading">{{message}}</div>
                        <div class="sub-heading body-copy">
                            If you need assistance with your account, please contact your Cartus Representative or the
                            <a href="mailto:helpdesk-cartus@cartus.com">Cartus Help Desk</a>
                        </div>
                        <div class="login-contain-btn btn-align-center">
                            <button mat-flat-button class="mat-button" [routerLink]="['/login']"
                                (click)=" message = ''; inactiveUser = false" type='button'>Back</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <app-footer *ngIf="appId !== 10 && loginAppFlag"></app-footer>
        </div>
    </div>
</div>