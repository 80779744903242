import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delegate-opt-out',
  templateUrl: './delegate-opt-out.component.html',
  styleUrls: ['./delegate-opt-out.component.scss']
})
export class DelegateOptOutComponent{
  logoConfig: any;
  constructor() { 
    this.logoConfig = { logo: 'cartus', type: 'main' }
  }
}
