import { Component, ViewEncapsulation, Input, HostBinding } from '@angular/core';

/** Avatar component to display the user Initials */
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvatarComponent {
  @HostBinding('class') class = 'app-avatar'

  /** the First Name data to be displayed */
  @Input() firstName: string;

  /** the Last Name data to be displayed */
  @Input() lastName: string;

  /** Base constructor method */
  constructor() { }

}
