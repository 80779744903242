import { Component, Input, EventEmitter, Output, OnInit} from '@angular/core';
import { SpringboardApp } from 'src/app/public/models/springboardApps.model';

@Component({
  selector: 'app-springboard-app',
  templateUrl: './springboard-app.component.html',
  styleUrls: ['./springboard-app.component.scss']
})

export class SpringboardAppComponent {

  @Input() application?: SpringboardApp;
  @Input() loginAppFlag : boolean;
  @Output() appSelected: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  //** emit the selected app to the parent component to be handled */
  selectApp() {
    this.appSelected.emit(this.application.name);
  }

}
