import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  /** Input parameter logoConfig received from parent component */
  @Input() logoConfig: any;
  constructor() { }

}
