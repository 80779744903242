import { Component, OnInit, OnDestroy, ViewEncapsulation, NgZone } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
import { ErrorMapping } from 'src/app/core/constants'
import { ForgotPassword } from '../../models/changePassword.model';

/** Base UnlockAccount component */
@Component({
  selector: 'app-unlockaccount',
  templateUrl: './unlockaccount.component.html',
  styleUrls: ['./unlockaccount.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UnlockaccountComponent implements OnInit, OnDestroy {
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Subscription for preference */
  prefSub: Subscription;
  /** Password display style, clear text or masked */
  hide: boolean;
  /*For the redirected URL */
  referredURL: string = '';
  /* Displays message to user (i.e. idle or session timeout) */
  message: string;
  /*Inactive user flag*/
  inactiveUser = false;
  /*Edge Chromium flag*/
  isEdgeChromium = false;
  /* Stores the route data */
  routeSub: any;
  /**
 * Initializes the value
 * @param router Instance Router
 */
  /**Base Constructor for Login Component
   * @param authSvc Authentication Service to authenticate the user details
   */
  templateString = {
    BNNER_TXT: `Technology That Moves You`,
    MOVEPRO360_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
      `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
      `needs, access predictive insights, and much more.`
  };
  /** Used to hold logo config value */
  logoConfig: any;
  /** Used to determine if the Google sign in is displayed */
  showGoogleButton: boolean = false;
  /** Used to hold the help links to display when the Google sign in is displayed */
  googleHelpLinks: string[] = ['Help'];
  /** Used to store appId */
  appId: number;
  //** GetLoginApp feture flag status */
  loginAppFlag: boolean = false;
  // used to store the username 
  username: string = '';
  // used to store the form submitted status
  isFormSubmitted: boolean = false;
  // used to store the mail sent status
  afterMailSent: boolean = false;
  // used to hold the error status flag value
  afterError: boolean = false;
  // used to hold the error msg getting through API call
  errorMessage: string;
  // used to store the payload to call unlock Account API
  unlockObj: ForgotPassword = {} as ForgotPassword;

  constructor(
    private readonly authSvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly appConfig: AppConfigService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly featureFlagService: FeatureFlagService
  ) {
    /**
     * To get the login app feature flag
     */
    // this.getFeatureFlagValue();
    /** Added for Edge Browser compatiblity */
    if (!window['TextEncoder']) {
      window['TextEncoder'] = TextEncoder;
    }
  }

  /** Unlock Account Component's Init Function */
  async ngOnInit() {

    this.appId = (sessionStorage.getItem('appId')) ? parseInt(sessionStorage.getItem('appId').replace(/"/g, "")) : 0;

    // Deleting cookies on ngOnInit
    this.cookieLogoSvc.removeAllCookies();
    this.titleService.setTitle('Unlock Account');
    // const googleIDPFeatureFlag = await this.getGoogleIDPFeatureFlag();
    /**
    * To get the login app feature flag
    */
    this.loginAppFlag = await this.getFeatureFlagValue();
    let routeData
    this.routeSub = this.route.data.subscribe(v => routeData = v);
    // (routeData['googleIdp']) && googleIDPFeatureFlag ? this.showGoogleButton = true : this.showGoogleButton = false
    this.isEdgeChromium = this.detectEdgeChromium();

    // Set logo based on referrer
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      this.referredURL = val ? val.replace(/(\/#|\/|#)$/, '') : '';
      const res = this.cookieLogoSvc.setLogo(this.referredURL)
      if (res) {
        this.logoConfig = { logo: res, type: 'main' }
      }
    });
  }

  // To get the email domain from user input.
  getEmailDomain(email: string) {
    const domainRegex = /@([\w-]+(\.[\w-]+)+)$/;
    const match = domainRegex.exec(email);
    if (match && match[1]) {
      return match[1]
    } else {
      return null;
    }
  }

  // get all the internal email domains from config.
  emailDomains = String(this.appConfig.getConfig('ANYWHERE_OKTA_DOMAINS'))

  async onUnlockAccount(): Promise<void> {
    this.afterError = false;
    this.isFormSubmitted = true;
    const usernameEl: any = document.querySelector("#username")
    this.username = usernameEl?.value
    if (this.username !== null && this.username && this.username !== null) {
      const domainName = this.getEmailDomain(this.username);
      let baseUrl;
      if (domainName && this.emailDomains.toLocaleLowerCase().includes(domainName.toLocaleLowerCase())) {
        baseUrl = String(this.appConfig.getConfig('ANYWHERE_OKTA_UNLOCK_ACCOUNT_URL'));
      } else {
        baseUrl = String(this.appConfig.getConfig('CARTUS_OKTA_UNLOCK_ACCOUNT_URL'));
      }
      this.spinner.show();
      this.unlockObj.username = this.username;
      this.unlockObj.factorType = 'EMAIL';
      this.unlockObj.baseURL = baseUrl;
      this.subscription.add(
        this.authSvc
          .unlockAccount(this.unlockObj)
          .subscribe(
            response => {
              this.spinner.hide();
              if (response && response.body.status === String(this.appConfig.getConfig('OKTA_UNL_ACCT_SUCC_MSG'))) {
                this.afterError = false;
                this.afterMailSent = true;
              } else {
                this.afterError = true;
                this.afterMailSent = false;
                this.errorMessage = response.body.errorSummary;
              }
            }
          )
      );
    }
  }

  /**
     * To get the GoogleIDP feature flag
     */
  // public async getGoogleIDPFeatureFlag() {
  //   return await this.featureFlagService.getgoogleIDPFeatureFlag();
  // }

  public async getFeatureFlagValue() {
    return await this.featureFlagService.getLoginApp('enable-login-app');
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
  detectEdgeChromium(): boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    return agent.indexOf('edg') > -1 ? true : false;
  }
}

