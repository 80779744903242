<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxFlex="100%" fxFlexFill fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center">
    <div fxFlex="0" fxFlex.gt-sm="50" class="left-content btn-align-center">
        <h1>{{templateString.BANNER_TXT}}</h1>
    </div>
    <div fxFlex.gt-sm="50" class="main-content">
      <div class="passwordSuccess">
        <app-logo [logoConfig]="logoConfig"></app-logo>
        <div class="info-txt">
          <span>{{contentText1}}</span><br />
          <span>{{contentText2}}</span>
        </div>
        <div *ngIf="showLoginButton" class="login-contain-btn btn-align-center">
          <button (click)="redirect()"  type="submit" id="redirect">
              {{templateString.LOGIN_BTN}}
          </button>
        </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>
